<template>
<div class="bignumber-title">
  <Title>
     <h3 class="title"> {{title}}</h3>
  </Title>
</div>
</template>

<script>
import Title from "@/components/atoms/Title"
export default {
    name:"TitleBigNumber",
    props: {
    'title': {
        type:String,
        required:true
        }
    },
    components:{Title}
}
</script>

<style lang="scss">
    .bignumber-title {
        .title {
            font-family: 'Poppins', sans-serif!important;  
            font-weight: 500;
            font-size: .875rem;
            line-height: 18px;
            text-align: center;
            color: #00853F;     
        }
    }
</style>