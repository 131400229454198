<template>
    <v-select
        v-model="selected"
        :options="options"
        :label="label"
        :inputId="inputId"
        @option:selected="actionHandler"
        @update:modelValue="tagRemoved"
    >
    </v-select>
</template>

<script>
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
export default {
    name:"VueSelect",
    components:{vSelect},
    data() {
        return {
            selected: this.setSelected()
        }
    },
    props:{
        "actionHandler":{
            type:Function,
            required:true
        },
        "actionDeselect":{
            type:Function,
            required:false
        },
        "options":{
            type:Array,
            required:true
        },
        "label":{
            type:String,
            required:true
        },
        "inputId":{
            type:String,
            required:true
        },
        "selectedId":{
            required:false
        }
    },
    watch: {
        selectedId: function() {
            this.selected = this.setSelected()
        }
    },
    methods: {
        tagRemoved(tag) {
            if (tag == null) {
                this.$props.actionDeselect();
            }
        },
        setSelected() {
            return this.$props.options.find(option => (option[this.$props.inputId] == this.$props.selectedId))
        }
    }
}
</script>