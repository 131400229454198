<template>
    <div class="form-check _to-right">
        <label class="form-check-label" :for="name">{{label}}</label>
        <input class="form-check-input" type="checkbox" :name="name" :ref="name" :id="id" :value="value" >
    </div> 
</template>

<script>
export default {
    name: 'Checkbox',
    props: {
        'name': {
            type:String,
            required:true
        },
        'id': {
            type:String,
            required:true
        },
        'value': {
            type:String,
            required:true
        },
        'label': {
            type:String,
            required:true
        },
        'checked': {
            type:String,
            required:true,
            default:"false"
        }
    },
    mounted() {   
        if (this.checked == "true") {
            this.$refs[this.name].checked = true
             this.$emit('change', this);
        } else {
            this.$refs[this.name].checked = false
        }
    }
}
</script>

<style lang="scss" scoped>
    ._to-right .form-check-input {
        float: right !important; //positioning right of label
        margin-right: 1.4rem;
    }
    .form-check {
        width: 100%;
        margin-top: 0rem;
        padding:0;
    }
    .form-check-input {
        border: 1px solid rgba(0, 133, 63, 0.5);
        background-color: transparent!important;
        border-color: #00853F!important;
    }
    .form-check-input:checked {
        background-color: transparent;
        border-color: #00853F;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2300853F' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")!important;
}
</style>
