<template>
  <p v-html="$t(id)"></p>
</template>

<script>
export default {
    name:"Paragraph",
    props:{
        'id':{
            type:String,
            required:true
        }
    }
}
</script>