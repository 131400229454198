<template>
<div class="chart-title">
  <Title>
     <h3 class="title"> {{$t(id)}}</h3>
  </Title>
</div>
</template>

<script>
import Title from "@/components/atoms/Title"
export default {
    name:"TitleLegendBox",
    props: {
    'id': {
        type:String,
        required:true
        }
    },
    components:{Title}
}
</script>

<style lang="scss">
    .chart-title {
        .title {
            font-size:.875rem;     
        }
    }
</style>