<template>
   <router-link :to="link" :taget="target" @click="handler">{{$t(id,['hello'])}}</router-link>
   <slot name="icon"></slot>
</template>
<script>
  export default {
    name: 'Link',
    emits: ["click"],
    props: {
      'id': {
        type:String,
        required:true
      },
      'link': {
        type:String,
        default:'',
        required:false
      },
      'target': {
        type:String,
        default:"_top"
      }
    },
    methods: {
       handler() {
            this.$emit('click')
       } 
    }
  }
</script>

<style lang="scss">
  a {
    font-family: 'Poppins', sans-serif;  
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-decoration-line: underline;
    color: #00853F !important;
    &:hover {
      color: #003318 !important;
    }

  }
</style>