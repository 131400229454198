<template>
    <loader v-if="loadingStatus"></loader>
    <v-select v-if="!loadingStatus"
        :selectedId="selected"
        :actionHandler="handler" 
        :actionDeselect="deselected"
        :options="getFormatedStates"
        placeholder="Escolha um estado"
        label="nm_uf"
        inputId="cd_uf"   
    >
    </v-select>
</template>

<script>
import vSelect from "../../atoms/Form/VueSelect.vue";
import { mapGetters, mapState } from 'vuex'
export default {
    name:"StatesTypeahead",
    components:{vSelect},
    emits: ["selected"],
    props:["currentCdUf"],
    data() {
        return {
            selected: this.$props.currentCdUf
        }
    },
    computed: {
        ...mapState({
            states: state => state.states.all
        }),
        ...mapGetters('states', {
            loadingStatus: 'loadingStatus',
            getFormatedStates: 'getFormatedStates'
        })
    },
    watch: {
        loadingStatus:function() {
            if (this.loadingStatus == false) {
                if (this.$props.currentCdUf) {
                    this.selectedUf(this.$props.currentCdUf)
                }
            }
        },
        currentCdUf: function(newVal) {
            this.selected = newVal  
            this.selectedUf(newVal)
        },
    },
    created() {
      this.$store.dispatch('states/getAllStates')
    },
    methods: {
       deselected() {
            this.$emit('selected',['',''])
       },
       handler(value) {
           let sigla_uf_arr = this.states.filter(state => state.cd_uf == value.cd_uf)
           let sigla_uf = sigla_uf_arr[0].sigla_uf
           this.$emit('selected',[value.cd_uf,sigla_uf])
       },
       selectedUf(cd_uf) {
           let sigla_uf_arr = this.states.filter(state => state.cd_uf == cd_uf)
           let sigla_uf = sigla_uf_arr[0].sigla_uf
           this.$emit('selected',[cd_uf,sigla_uf])
       } 
    }

}
</script>