<template>
    <tr>
        <slot />
    </tr>
</template>

<script>
export default {
    name:"AtomTr"   
}
</script>

<style>
td{
    font-family: 'Poppins', sans-serif!important;  
    font-size: .625rem;
    line-height: 15px;
    text-align: center;
}
</style>