<template>
  <div>
    <slot name="title"></slot>
    <DoughnutChart :chartData="chartData" v-if="chartData !== null" />
    <NoResultsTitle :id="noResultsId" v-if="chartData == null"></NoResultsTitle>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
import { DoughnutChart } from "vue-chart-3";
import NoResultsTitle from '../NoResultsTitle.vue';

Chart.register(...registerables);

export default {
  name: "DoughnutChartComponent",
  props: ["dataChart"],
  components: { DoughnutChart, NoResultsTitle },
  computed: {
    noResultsId: function() {
      // No Data
      if(this.$props.dataChart == undefined) {
        return "no-results";
      } else if(this.chartSum == 0) {
        return "no-deficit";
      } else {
        return "no-results";
      }
    },
    chartSum: function() {
      // All results are 0
      let dataSum = 0;
      this.$props.dataChart[1].forEach(num => {
          dataSum += parseFloat(num);
      });
      return dataSum;
    },
    chartData: function () {
      // No Data
      if(this.$props.dataChart == undefined || this.chartSum == 0) {
        return null;
      }     


      // Creates the Chart
      let chart = {
        labels: this.$props.dataChart[0],
        datasets: [
          {
            data: this.$props.dataChart[1],
            backgroundColor: ["#00853F", "#0081C6", "#cccccc", "#FDBB30"],
          },
        ],
      };
      return chart;
    },
  },
};
</script>