<template>
    <Table v-if="isEmpty == false">
        <template v-slot:thead>
            <Tr>
                <Th scope="col" v-for="(item,index) in this.getHeader" :key="index">
                    {{item.column}}
                </Th>
            </Tr>
        </template>
        <template v-slot:tbody>
            <Tr v-for="(rows,index) in this.getBody" :key="index">
                <component :is="subindex != 'column1' ? 'Td' : 'Th'" scope="row" v-for="(row,subindex) in rows" :key="subindex">
                    {{row}}
                </component>
            </Tr>
        </template>
    </Table>
    <NoResultsTitle v-if="isEmpty"></NoResultsTitle>
    <div class="row pt-5">
        <div class="col-6">
            <div class="btn-ok-width-filter">
                <Button id="prev" @click="onPrevPage" v-if="getHasPagination && isEmpty == false"/>
            </div>
        </div>
        <div class="col-6">
            <div class="btn-ok-width-filter">
                <Button id="next" @click="onNextPage" v-if="getHasPagination && isEmpty == false"/>
            </div>
        </div>
    </div>
</template>

<script>
import Table from "../../atoms/Table/Table"
import Tr from "../../atoms/Table/Tr"
import Th from "../../atoms/Table/Th"
import {toRaw } from 'vue';
import NoResultsTitle from '../../atoms/NoResultsTitle';
import Button from "../../atoms/Button"

export default {
    name:"TableDashboard",
    props: {
        'list': {
            required:true,
        },
        'enablePagination' : {
            required: false,
            type: Boolean
        },
        'onNextPage' : {
            type: Function
        },
        'onPrevPage' : {
            type: Function
        }
    },
    components:{Table,Tr,Th,NoResultsTitle,Button},
    computed: {
        getHasPagination() {
            return this.$props.enablePagination;
        },
        getHeader() {
            let headers = toRaw(this.list)
            return headers.table[0].header
        },
        getBody() {
            let rows = toRaw(this.list)
            return rows.table[0].body
        },
        isEmpty: function() {
            if(this.list == null || this.list.table == null) {
                return true;
            }

           return this.list.table[0].body.length <= 0;
        }
    }
}
</script>

<style>
    .btn-ok-width-filter{
        width: 410px!important;
        margin:auto auto;
    }
    @media only screen and (max-width: 600px) {
        .btn-ok-width-filter {
            width: 170px!important;
        }
    }
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
</style>