<template>
    <div class="big-number-box">
        <Title :title="title" v-if="title"></Title>
        <big-number>
                  {{ bigNumber }}
        </big-number>
        <Paragraph :id="paragraph" />
    </div>
</template>

<script>
import BigNumber from "../../atoms/Charts/BigNumber"
import Title from "../Titles/BigNumber"
import Paragraph from "../../atoms/Paragraph"
export default {
    name:"BigNumberBox",
    components: {BigNumber,Title,Paragraph},
    props: {
        title:{
            type:String,
            required:false
        },
        number:{
            required:true
        },
        formatNumber:{
            default:true
        },
        paragraph:{
            type:String,
            required:true
        },
    },
    data() {
        return {
            bigNumber: this.$props.formatNumber ? this.$filters.formatNumber(this.$props.number) : this.$props.number
        }
    }
}
</script>

<style lang="scss">
.big-number-box{
    text-align: center;
    p {
        font-family: 'Poppins', sans-serif!important;
        font-size: 1rem;
        line-height: 18px;
        text-align: center;
    }
}    
</style>