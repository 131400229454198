<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <slot name="thead"></slot>
            </thead>
            <tbody>
                <slot name="tbody"></slot>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name:"AtomTable"
}
</script>
<style>
    thead {
        background-color:rgba(0, 133, 63, 0.2) !important;
        font-family: 'Poppins', sans-serif!important;  
        font-weight: 600!important;
        font-size: .625rem;
        line-height: 15px;
        text-align: center;
    }
    tr:nth-child(even) {
        background-color: rgba(0, 133, 63, 0.1);
    }
</style>