<template>
  <th :scope="scope "><slot /></th>
</template>

<script>
export default {
    name:"AtomTh",
    props:{
        'scope':{
            type:String,
            required:true,
            default:"col"
        }
    }
}
</script>
<style scoped>
th{
    font-family: 'Poppins', sans-serif!important;  
    font-size: .625rem;
    line-height: 15px;
    text-align: center;
}
</style>