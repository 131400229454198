<template>
    <div class="text-center">
        <button class="btn btn-ok" type="button">{{$t(id)}}</button>
    </div>
</template>

<script>
export default {
    name:"Button",
    props:{
        'id':{
            type:String,
            required:true
        }
    }
}
</script>

<style>
.btn-ok{
    font-family: 'Poppins', sans-serif!important;  
    background-color: #00853F!important;
    border-radius: 16px!important;
    font-weight: bold!important;
    font-size: 1.125rem!important;
    line-height: 27px!important;
    color: #FFFFFF!important;
    width: 100%!important;
}
.btn-ok:hover{
    color: #FFFFFF;
}
@media only screen and (max-width: 600px) {
    .btn-ok {
        width: 100%!important;
    }
}
</style>