<template>
  <h1 class="big-number">
      <slot/>
  </h1>
</template>

<script>
export default {
    name:"BigNumber"
}
</script>

<style>
    .big-number {
        font-family: 'Poppins', sans-serif!important;  
        font-weight: 700!important;
        font-size: 3rem;
        line-height: 48px;
    }
@media only screen and (max-width: 600px) {
    .big-number {
        font-size: 2rem;
        line-height: 30px;
    }
}
</style>