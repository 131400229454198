<template>
    <div class="chart-title text-center p-5">
        <h3 class="title">{{$t(id)}}</h3>
    </div>
</template>

<script>
export default {
    name:"NoResultsTitle",
    props: {
        'id': {
        type:String,
        required:false,
        default: function() {
            return "no-results";
        }
        }
    }
}
</script>

<style>

</style>